import React from "react";
import styled from "styled-components";
import ProductParam, {
  getDefaultConfigWithExtra,
  findExtraDisplayInfo,
  getProductSelectNames,
  ProductSizeParam,
} from "../ProductParam";
const constants = require("../../constants");

function NewProductConfig({
  product,
  config,
  onChange,
  viewOnly,
  configChangesMode,
}) {
  const selectNames = getProductSelectNames(product);

  return (
    <Wrapper>
      {selectNames
        .filter(
          selectName => !["width_select", "length_select"].includes(selectName)
        )
        .map(selectName => (
          <ProductParam
            newVersion
            key={selectName}
            selectName={selectName}
            param={product[selectName]}
            value={config[selectName]}
            configChangesMode={configChangesMode}
            setValue={value => {
              let nextConfig = {
                ...config,
                [selectName]: value,
              };
              let nextExtra = findExtraDisplayInfo(
                product[selectName],
                value,
                config
              );
              let resp = [null, null];

              if (selectName === "side_select") {
                alert(
                  `由於您修改了${product[selectName].label}設定, 請重新選擇其餘選項`
                );
                resp = getDefaultConfigWithExtra({ product, side: value });
                nextConfig = resp[0];
                nextExtra = resp[1];
                nextConfig.side_select = value;
              } else if (product[selectName].type === "master") {
                if (value.material !== config[selectName].material) {
                  resp = getDefaultConfigWithExtra({
                    product,
                    material: value.material,
                  });

                  nextConfig = resp[0];
                  nextExtra = resp[1];
                  nextConfig.side_select = config.side_select;
                }
              }

              onChange(nextConfig, nextExtra);
            }}
            side={config["side_select"]}
            config={config}
            viewOnly={viewOnly}
          />
        ))}

      {selectNames.includes("width_select") &&
        selectNames.includes("length_select") && (
          <ProductSizeParam
            product={product}
            value={{
              width_select: config["width_select"],
              length_select: config["length_select"],
            }}
            setValue={values => {
              let nextConfig = {
                ...config,
                ...values,
              };

              onChange(nextConfig);
            }}
            viewOnly={viewOnly}
            newVersion
            configChangesMode
          />
        )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow-y: auto;
  margin-top: 16px;

  @media only screen and (max-width: ${constants.breakpoints.sm}px) {
    display: flex;
    flex-direction: column;

    & > :first-child {
      border-bottom: 1px solid #f2f2f2;
    }
  }
`;

export default NewProductConfig;
