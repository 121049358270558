import React, { useState } from "react";
import styled from "styled-components";
import { Form, Input, Button, Select } from "antd";

import { TWO_COPIES_TYPES, LOVECODE_OPTIONS } from "../../dictionary";

const constants = require("../../constants");

const renderContent = ({ invoiceConfig, setConfig }) => {
  switch (invoiceConfig.invoice_type) {
    case "two_copies":
      return (
        <TwoPartInvoice invoiceConfig={invoiceConfig} setConfig={setConfig} />
      );
    case "three_copies":
      return (
        <ThreePartInvoice invoiceConfig={invoiceConfig} setConfig={setConfig} />
      );
    case "donate":
      return (
        <DonateInvoice invoiceConfig={invoiceConfig} setConfig={setConfig} />
      );
    default:
      return null;
  }
};

const TwoPartInvoice = ({ setConfig, invoiceConfig }) => {
  return (
    <StyledFormContainer layout="vertical">
      <StyledFormItem label="發票類型" layout="vertical" name="invoice_subtype">
        <StyledSelect
          placeholder="選擇發票開立方式"
          defaultValue={invoiceConfig.invoice_subtype || null}
          onChange={value => {
            setConfig("invoice", { invoice_subtype: value });
          }}
          options={Object.values(TWO_COPIES_TYPES).map(type => ({
            value: type.value,
            label: type.label,
          }))}
        />
      </StyledFormItem>
      {invoiceConfig.invoice_subtype === "citizen_personal_certificate" ? (
        <StyledFormItem
          label="條碼編號 ( 2 個英文字母 + 14 個數字)"
          name="citizen_personal_certificate"
        >
          <Input
            placeholder="請輸入16碼卡號"
            defaultValue={invoiceConfig.citizen_personal_certificate}
            onChange={e =>
              setConfig("invoice", {
                citizen_personal_certificate: e.target.value,
              })
            }
          />
        </StyledFormItem>
      ) : invoiceConfig.invoice_subtype === "mobile_vehicle" ? (
        <StyledFormItem label="條碼編號" name="mobile_vehicle">
          <Input
            placeholder="輸入”/”與後7碼"
            defaultValue={invoiceConfig.mobile_vehicle_code}
            onChange={e =>
              setConfig("invoice", { mobile_vehicle_code: e.target.value })
            }
          />
        </StyledFormItem>
      ) : null}
      {invoiceConfig.invoice_subtype === "mobile_vehicle" ? (
        <BottomTextContainer>
          <p>手機條碼將同步更新於會員專區內 </p>
        </BottomTextContainer>
      ) : (
        <BottomTextContainer>
          <p>
            本網站訂單一律開立電子發票，並將發票號碼上傳至政府平台，開立資訊會寄送至會員電子信箱不另寄紙本發票。
          </p>
        </BottomTextContainer>
      )}
    </StyledFormContainer>
  );
};

const ThreePartInvoice = ({ invoiceConfig, setConfig }) => {
  return (
    <StyledFormContainer layout="vertical">
      <StyledFormItem label="統一編號" name="gui_number">
        <Input
          placeholder="統一編號"
          defaultValue={invoiceConfig.gui_number}
          onChange={e => setConfig("invoice", { gui_number: e.target.value })}
        />
      </StyledFormItem>
      <StyledFormItem label="公司抬頭" name="company_title">
        <Input
          placeholder="公司抬頭"
          defaultValue={invoiceConfig.company_title}
          onChange={e =>
            setConfig("invoice", { company_title: e.target.value })
          }
        />
      </StyledFormItem>
      <Button
        target="_blank"
        href="https://www.etax.nat.gov.tw/etwmain/etw113w1/ban/query"
        style={{
          border: "none",
          backgroundColor: "transparent",
          color: constants.colors.highlight,
          boxShadow: "none",
          textAlign: "start",
          padding: 0,
          height: "24px",
        }}
      >
        查詢公司抬頭統編
      </Button>
    </StyledFormContainer>
  );
};

const DonateInvoice = ({ invoiceConfig, setConfig }) => {
  return (
    <StyledFormContainer layout="vertical">
      <StyledFormItem label="愛心碼" name="love_code">
        <StyledSelect
          placeholder="選擇社福團體"
          defaultValue={invoiceConfig.love_code || null}
          onChange={value => {
            setConfig("invoice", { love_code: value });
          }}
          options={LOVECODE_OPTIONS.map(option => ({
            value: option.value,
            label: option.label,
          }))}
        />
      </StyledFormItem>
    </StyledFormContainer>
  );
};

export default renderContent;

const StyledFormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 14px;
  width: 100%;
  .ant-form-item-label > label {
    color: #40444c;
  }
  input {
    height: 48px;
    border: 1px solid #b6bac3;
    border-radius: 6px;
    padding: 12px 16px;
    align-items: center;
    font-size: 16px;
  }
  .ant-radio-wrapper {
    font-size: 14px;
    color: #505050;
  }

  .ant-form-item {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-select.ant-select-in-form-item {
    margin: 0;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: inline-flex;
    align-items: center;
  }
`;

const BottomTextContainer = styled.div`
  position: absolute;
  bottom: 24px;
  padding-right: 24px;

  @media only screen and (max-width: ${constants.breakpoints.lg}px) {
    position: static;
    padding-right: 0;
  }

  p {
    font-size: 14px;
    color: #5b616e;
    margin: 0;
  }
`;

const StyledSelect = styled(Select)`
  font-size: 16px;
  color: #505050;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: solid 1px #b6bac3;

  .ant-select-customize-input {
    padding: 0 16px;
  }

  .ant-select-arrow {
    color: #1c1b1f;
    right: 16px;
  }
`;
