import React, { useState, useEffect, useContext } from "react";
import { Modal } from "antd";
import styled from "styled-components";

import NewProductConfig from "./NewProductConfig";
import { SelectRow } from "../ProductParam";
import { Text, Button } from "../../Widgets";
import { Context } from "../../AppContext";

const constants = require("../../constants");

export default function ProductConfigModal(props) {
  const {
    product,
    config: initialConfig,
    customNote,
    visible,
    productName,
    initRequireDay,
    onCancel,
    onConfirm,
  } = props;
  const app = useContext(Context);
  const [configChanges, setConfigChanges] = useState(initialConfig);
  const [requireDay, setRequireDay] = useState(0);

  const handleConfigChange = async nextConfig => {
    app.actions.setLoading(true);
    setConfigChanges(nextConfig);
    app.actions.setLoading(false);
  };

  useEffect(() => {
    setConfigChanges(initialConfig);
  }, [initialConfig]);

  useEffect(() => {
    setRequireDay(initRequireDay);
  }, [initRequireDay]);

  return (
    <StyledModal
      title="修改商品規格"
      centered
      closable={true}
      visible={visible}
      onCancel={onCancel}
      footer={<Footer onConfirm={() => onConfirm(configChanges)} />}
    >
      <Text
        size="md"
        weight="500"
        color={constants.colors.text}
        style={{ marginBottom: "16px" }}
      >
        {productName}
      </Text>
      {configChanges && (
        <NewProductConfig
          product={product}
          config={configChanges}
          requireDay={requireDay}
          configChangesMode={true}
          onChange={nextConfig => {
            handleConfigChange(nextConfig);
          }}
        />
      )}
      <SelectRow className="require-day">
        <label
          style={{
            paddingTop: 0,
            marginBottom: 8,
            fontSize: 16,
            color: "#000",
          }}
        >
          製作時間
        </label>
        <div className="require-day-text">
          <Text weight="700">{requireDay} 天</Text>
          <Text size="sm" color="#6b7280">
            審稿成功後隔日起算工作天，工作天不含假日及運送時間
          </Text>
        </div>
      </SelectRow>

      {customNote && (
        <SelectRow>
          <label style={{ paddingTop: 0 }}>自定規格</label>
          <div className="require-day-text">
            <Text size="sm" inline>
              {customNote}
            </Text>
          </div>
        </SelectRow>
      )}
    </StyledModal>
  );
}

const Footer = ({ onConfirm }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Button size="lg" type="secondary" onClick={onConfirm}>
        確認
      </Button>
    </div>
  );
};

const StyledModal = styled(Modal)`
  width: 722px;
  height: 80%;

  @media only screen and (max-width: ${constants.breakpoints.md}px) {
    max-width: 100vw;
    max-height: 100vh;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .ant-modal-content {
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: ${constants.breakpoints.md}px) {
      border-radius: 0;
    }
  }
  .ant-modal-header {
    text-align: start;
    border-radius: 10px 10px 0 0;
    padding: 24px;
    border: none;

    @media only screen and (max-width: ${constants.breakpoints.md}px) {
      text-align: center;
    }
    .ant-modal-title {
      color: #000;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: -0.48px;
    }
  }
  .ant-modal-body {
    padding: 0 24px;
    overflow-y: scroll;
    height: 70vh;
    padding-bottom: 104px;

    @media only screen and (max-width: ${constants.breakpoints.md}px) {
      height: 73%;
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: end;
    padding: 24px;
    border: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: ${constants.colors.background};

    @media only screen and (max-width: ${constants.breakpoints.md}px) {
      display: block;
      button {
        width: 100%;
      }
    }
  }

  .ant-modal-close {
    @media only screen and (max-width: ${constants.breakpoints.md}px) {
      left: 0;
    }
  }
  .ant-modal-close-x {
    margin: 24px;
    color: #25272c;
    width: 30px;
    height: 30px;
    line-height: 36px;

    .ant-modal-close-icon {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .require-day {
    .require-day-text {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media only screen and (max-width: ${constants.breakpoints.md}px) {
        width: 230px;
      }

      @media only screen and (max-width: ${constants.breakpoints.sm}px) {
        width: 100%;
      }
    }

    @media only screen and (max-width: ${constants.breakpoints.sm}px) {
      padding: 0;
      margin-top: 16px;
    }
  }
`;
