import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import styled from "styled-components";
import slugify from "uslug";
import { Text } from "../../Widgets";
import * as Icon from "../../Widgets/Icon";
import * as Ant from "antd";
import * as Cart from "../../Contexts/CartContext";
import { Context } from "../../AppContext";
import { ErrCart, ErrCartItem, errorHandler } from "../../errors";

import Item from "./Item";
import ProductConfigSummary from "./ProductConfigSummary";
import ProductConfigModal from "./ProductConfigModal";
import PromotionSection from "./PromotionSection";

const constants = require("../../constants");

export default function CartItemTable({ goToPrevStep, cartData }) {
  const app = useContext(Context);

  const [selectedItem, setSelectedItem] = useState({
    index: null,
    product: null,
    config: null,
    custom_note: null,
    productName: null,
    require_day: null,
  });
  const [configModalVisible, setConfigModalVisible] = useState(false);
  const [spec, setSpec] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [promos, setPromos] = useState({});

  const openConfigModal = (
    product,
    config,
    customNote,
    orderType,
    index,
    productName,
    requireDay,
    productID
  ) => {
    const result = {
      product,
      config,
      index,
      productName,
      require_day: requireDay,
      productID,
    };
    if (orderType === "custom") {
      result.custom_note = customNote;
    }
    setSelectedItem(prevState => ({ ...prevState, ...result }));
    setConfigModalVisible(true);
  };

  const closeConfigModal = () => {
    setSelectedItem({
      product: null,
      config: null,
      custom_note: null,
    });
    setConfigModalVisible(false);
  };

  const handleConfigOnConfirm = config => {
    updateOrderItems(selectedItem.product, config, selectedItem.index);
    closeConfigModal();
  };

  useEffect(() => {
    (async () => {
      if (cartData) {
        setLoading(true);
        try {
          let spec = await app.actions.getSpec(cartData.version);
          setSpec(spec);
          let resp = await app.actions.getPromotions();
          setPromos(resp);
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      }
    })();
  }, [cartData]);

  useEffect(() => {
    setTableColumns(getColumns());
  }, [cartData]);

  const updateOrderItems = useCallback(
    async (product, config, itemIndex) => {
      app.actions.setLoading(true);
      try {
        await Cart.Actions.updateItem(
          itemIndex,
          {
            name: product.name,
            config,
          },
          spec?.version
        );
      } catch (ex) {
        if (ex?.error === "product is out of stock") {
          ex = new ErrCartItem("商品已售完");
        } else if (ex?.error === "calc_error") {
          ex = new ErrCartItem(
            "商品規格已更新，購物車將會清除舊版商品，請重新加入購物車。"
          );
          // 如果商品規格驗證失敗，則重新撈取購物車資訊。
          Cart.Actions.fetchCart();
        }
        errorHandler(ex);
      }
      app.actions.setLoading(false);
    },
    [spec]
  );

  const removeItem = useCallback(async (index, item) => {
    try {
      await Cart.Actions.removeItem(index, item);
    } catch (err) {
      if (err.error === "items_version_expired") {
        Ant.message.error("購物車商品已過期，請重新整理");
        await Cart.Actions.fetchCart();
      }
      console.warn("err", err);
    }
  }, []);

  const columns = useRef([
    {
      name: "spec",
      label: "商品規格",
      render: ({ item, index, product, viewOnly, order, updateOrderItems }) => {
        const selectName = "quantity_select";
        return (
          <SpecContainer>
            <ImageContainer>
              {item.image && <img src={item.image} alt="product" />}
            </ImageContainer>
            <ProductInfoContainer>
              <ProductName
                onClick={() => {
                  if (
                    typeof window !== "undefined" &&
                    order?.order_type !== "custom"
                  ) {
                    window.open(`/product/${slugify(item.name)}/`, "_blank");
                  }
                }}
              >
                {(order && order.custom_note) || item.name}
              </ProductName>
              <MobileEditContainer
                onClick={() =>
                  openConfigModal(
                    product,
                    item.config,
                    item.custom_note,
                    order?.order_type,
                    index,
                    item.name,
                    item.require_day,
                    item.product_id
                  )
                }
              >
                <img
                  src="/images/edit.svg"
                  alt="edit"
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                />
                <Text size="sm" color={constants.colors.highlight}>
                  編輯
                </Text>
              </MobileEditContainer>
              <ProductConfigSummary
                product={product}
                itemIndex={index}
                item={item}
                setValue={value => {
                  let nextConfig = {
                    ...item.config,
                    [selectName]: value,
                  };
                  updateOrderItems(nextConfig);
                }}
                viewOnly={viewOnly}
              />
            </ProductInfoContainer>
          </SpecContainer>
        );
      },
      className: "spec",
    },
    {
      name: "quantity",
      label: "數量",
      render: ({ item, product, updateOrderItems, index, order }) => {
        const selectName = "quantity_select";

        const param = product[selectName];
        const value = item.config[selectName];
        const config = item.config;

        return (
          <div style={{ display: "flex", gap: 4, width: 110 }}>
            <Text size="sm" weight="700">
              {value}
            </Text>

            <img
              src="/images/edit_gray.svg"
              alt="edit"
              style={{
                width: "20px",
                cursor: "pointer",
                color: "#000",
              }}
              onClick={() =>
                openConfigModal(
                  product,
                  item.config,
                  item.custom_note,
                  order?.order_type,
                  index,
                  (order && order.custom_note) || item.name,
                  item.require_day,
                  item.product_id
                )
              }
            />
          </div>
        );
      },
      className: "quantity",
    },
    {
      name: "require_day",
      label: "製作天數",
      render: ({ item }) => (
        <Ant.Tooltip
          title="審稿成功後隔日起算工作天，不含假日及運送時間"
          overlayInnerStyle={{
            backgroundColor: "#25272c",
            color: "#d8dBdf",
            border: "1px solid #40444c",
            borderRadius: "5px",
            padding: "8px 12px",
          }}
          placement="bottom"
        >
          <div style={{ display: "flex", gap: 4 }}>
            <span
              style={{
                fontWeight: "700",
                fontSize: "16px",
                color: "#000",
                lineHeight: "24px",
              }}
            >
              {`${item.require_day}天`}
            </span>
            <Icon.InfoCircle
              size={16}
              color={"#1c1b1f"}
              css={{ cursor: "pointer" }}
            />
          </div>
        </Ant.Tooltip>
      ),
      className: "require-day",
    },
    {
      name: "price",
      label: "商品總價",
      render: ({ product, item, index, order }) => {
        return (
          <>
            <span
              style={{
                fontWeight: "700",
                fontSize: "16px",
                color: "#000",
                lineHeight: "24px",
              }}
            >
              ${" "}
              {item.amount === item.price
                ? (item.amount * 1.05).toFixed(0)
                : item.price}
            </span>
            <ItemEditContainer>
              <img
                src="/images/edit.svg"
                alt="edit"
                style={{ width: "24px", cursor: "pointer" }}
                onClick={() =>
                  openConfigModal(
                    product,
                    item.config,
                    item.custom_note,
                    order?.order_type,
                    index,
                    item.name,
                    item.require_day,
                    item.product_id
                  )
                }
              />
              <img
                src="/images/delete.svg"
                alt="delete"
                style={{ width: "24px", cursor: "pointer" }}
                onClick={() => removeItem(index, item)}
              />
            </ItemEditContainer>
          </>
        );
      },
      className: "price",
    },
  ]).current;
  /*
  order && orderItem >  attatchment and status
  !viewOnly > delete
  */

  const getColumns = useCallback(() => {
    return columns.filter(col => {
      if (col.name === "delete") {
        return false;
      }
      if (col.name === "edit") {
        return false;
      }
      if (["attatchment", "status"].includes(col.name)) {
        return false;
      }

      return true;
    });
  }, [cartData]);

  if (cartData && cartData.items.length === 0) {
    return <div style={{ textAlign: "center" }}>您的購物車為空！</div>;
  }

  if (loading || !spec) {
    return <div style={{ textAlign: "center" }}>載入中...</div>;
  }

  return (
    <Table>
      <Header columns={tableColumns} />
      {cartData && spec ? (
        cartData.items.map((item, idx) => (
          <Item
            key={idx}
            index={idx}
            item={item}
            spec={spec}
            columns={tableColumns}
            updateOrderItems={config => updateOrderItems(item, config, idx)}
            promos={promos}
          />
        ))
      ) : (
        <Text style={{ textAlign: "center" }}>載入商品規格中...</Text>
      )}
      {selectedItem && (
        <ProductConfigModal
          product={selectedItem.product}
          config={selectedItem.config}
          customNote={selectedItem.custom_note}
          visible={configModalVisible}
          productName={selectedItem.productName}
          initRequireDay={selectedItem.require_day}
          onCancel={closeConfigModal}
          onConfirm={config => handleConfigOnConfirm(config)}
        />
      )}
      <PromotionSection
        cartData={cartData}
        promos={promos}
        goToPrevStep={goToPrevStep}
      />
    </Table>
  );
}

export function Header(props) {
  let { columns } = props;

  return (
    <HeaderBlock>
      {columns.map((col, idx) => (
        <Col key={idx} style={col.style} className={col.className}>
          {col.label}
        </Col>
      ))}
    </HeaderBlock>
  );
}

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-bottom: solid 0.5px #edeef1;
  flex: ${props => props.flex || 1};

  @media only screen and (max-width: ${constants.breakpoints.lg}px) {
    display: none;
  }

  ${props => props.css};
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  border: solid 0.5px #edeef1;
  background-color: white;

  @media only screen and (max-width: ${constants.breakpoints.md}px) {
    border-radius: 0;
  }
`;

const SpecContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: relative;

  @media only screen and (max-width: ${constants.breakpoints.lg}px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  display: block;
  width: 76px;
  height: 76px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 5px;
  }

  @media only screen and (max-width: ${constants.breakpoints.lg}px) {
    width: 84px;
    height: 84px;
  }
`;

const ProductName = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.016px;
  cursor: pointer;
  margin-bottom: 8px;
  color: #000;

  @media only screen and (max-width: ${constants.breakpoints.lg}px) {
    position: absolute;
    top: 0;
    left: 100px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.02px;
  }

  @media only screen and (max-width: ${constants.breakpoints.sm}px) {
    margin-right: 32px;
    max-height: 84px;
    overflow: scroll;
  }
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  font-weight: 500;
  line-height: 20px;
  padding: 16px 20px;

  &.spec {
    flex: 1 1 40%;
    align-self: start;

    @media only screen and (max-width: ${constants.breakpoints.lg}px) {
      flex: 1 1 100%;
      padding: 0 24px;
    }
  }

  &.quantity {
    flex: 0 0 150px;
    align-items: start;

    @media only screen and (max-width: ${constants.breakpoints.xl}px) {
      display: none;
    }
  }

  &.require-day {
    flex: 0 0 110px;
    align-items: start;

    @media only screen and (max-width: ${constants.breakpoints.lg}px) {
      display: none;
    }
  }

  &.price {
    flex: 0 0 130px;
    align-items: start;

    @media only screen and (max-width: ${constants.breakpoints.lg}px) {
      display: none;
    }
  }
`;

const ItemEditContainer = styled.div`
  display: flex;
  gap: 16px;
  position: absolute;
  bottom: 20px;
  height: 24px;

  @media only screen and (max-width: ${constants.breakpoints.lg}px) {
    top: 20px;
    right: 24px;
  }

  @media only screen and (max-width: ${constants.breakpoints.sm}px) {
    & > :first-child {
      display: none;
    }
  }
`;

const MobileEditContainer = styled.div`
  display: none;

  @media only screen and (max-width: ${constants.breakpoints.sm}px) {
    width: 100%;
    height: 84px;
    display: flex;
    align-items: top;
    gap: 4px;
    position: absolute;
    top: 116px;
    left: 0;
  }
`;
