import React, { useMemo } from "react";
import styled from "styled-components";

import { Text } from "../../Widgets";
import * as Icon from "../../Widgets/Icon";

const constants = require("../../constants");

function PromotionSection({ cartData, promos, goToPrevStep }) {
  const result = useMemo(() => {
    let feePromotionResult = [];
    let thresholdPromotionResult = [];

    const feePromotion = promos["fee_promotion"];
    if (feePromotion) {
      const exclude = cartData.items.some(item =>
        feePromotion.exclude_product.includes(item.name)
      );
      feePromotionResult.push(
        exclude
          ? `您的購物車包含無免運活動之商品，不符合${feePromotion.name}條件！`
          : `商品差 ${promos.fee_promotion_diff} 元，免運`
      );
    }

    for (let i = 1; ; i++) {
      const thresholdPromo = promos[`threshold_promotion_${i}`];
      if (!thresholdPromo) break;
      thresholdPromotionResult.push(
        `商品差 ${promos[`threshold_promotion_diff_${i}`]} 元，折抵 ${
          thresholdPromo.value
        } 元`
      );
    }

    return {
      feePromotionResult,
      thresholdPromotionResult,
    };
  }, [cartData.items, promos]);

  const KeepShopping = () => {
    return (
      <Text
        className="keep-shopping"
        size="ms"
        weight="500"
        color={constants.colors.highlight}
        css={{ cursor: "pointer" }}
        onClick={goToPrevStep}
      >
        前往選購
      </Text>
    );
  };

  const hasPromotion =
    result.feePromotionResult.length > 0 ||
    result.thresholdPromotionResult.length > 0;

  return hasPromotion ? (
    <Footer>
      {result.feePromotionResult.length > 0 && (
        <FreeShippingContainer>
          <FreeShippingTitle>
            <Icon.ExclamationCircle size={20} color={constants.colors.text} />
            未達免運標準
          </FreeShippingTitle>
          {result.feePromotionResult.map((name, i) => (
            <Text key={i} size="xs" color="#505050" weight="400">
              {" "}
              {name}
            </Text>
          ))}
          <KeepShopping />
        </FreeShippingContainer>
      )}
      {result.thresholdPromotionResult.length > 0 && (
        <FreeShippingContainer>
          <FreeShippingTitle>
            <Icon.ExclamationCircle size={20} color={constants.colors.text} />
            未達折扣標準
          </FreeShippingTitle>
          {result.thresholdPromotionResult.map((name, i) => (
            <Text key={i} size="xs" color="#505050" weight="400">
              {name}
            </Text>
          ))}
          <KeepShopping />
        </FreeShippingContainer>
      )}
      {result.thresholdPromotionResult.length > 0 && (
        <FreeShippingContainer>
          <FreeShippingTitle>
            <Icon.ExclamationCircle size={20} color={constants.colors.text} />
            未達折扣標準
          </FreeShippingTitle>
          {result.thresholdPromotionResult.map((name, i) => (
            <Text key={i} size="xs" color="#505050" weight="400">
              {name}
            </Text>
          ))}
          <KeepShopping />
        </FreeShippingContainer>
      )}
    </Footer>
  ) : null;
}

const Footer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid #6b7280;

  @media screen and (max-width: ${constants.breakpoints.sm}px) {
    & > :not(:last-child) {
      & > .keep-shopping {
        display: none;
      }
    }
  }
`;

const FreeShippingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: ${constants.breakpoints.sm}px) {
    flex-direction: column;
    gap: 6px;
    align-items: start;
  }
`;

const FreeShippingTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  color: ${constants.colors.text};
  padding: 0 20px;
  gap: 4px;
`;

export default PromotionSection;
