import React, { useState, useCallback, useEffect, useContext } from "react";
import styled from "styled-components";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import CaretDownOutlined from "@ant-design/icons/CaretDownOutlined";
import { ArrowDown } from "../../Widgets/Icon";
import { Spin, Input as AntInput, Select as AntSelect, Form } from "antd";
import { Context } from "../../AppContext";
import formatValidator from "../../Utils/formatValidator";

const constants = require("../../constants");

export default function AddressField({
  city,
  address = "",
  disabled = false,
  prefix = null,
  form,
  setValid,
}) {
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedCity, setSelectedCity] = useState(city);
  const { isNotEmpty, isValidZipCode } = formatValidator;

  const app = useContext(Context);

  const checkedAddressIsValid = useCallback(() => {
    const zipCode = form.getFieldValue(
      prefix && prefix === "sender_" ? `${prefix}zip` : "zip_code"
    );
    const city = form.getFieldValue(prefix ? `${prefix}city` : "city");
    const district = form.getFieldValue(
      prefix ? `${prefix}district` : "district"
    );
    if (
      !isNotEmpty(zipCode) ||
      !isValidZipCode(zipCode) ||
      !isNotEmpty(city) ||
      !isNotEmpty(district)
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [form, isNotEmpty, prefix, setValid]);

  const getZipCode = useCallback(
    async zip_code => {
      setLoading(true);
      try {
        let resp = await app.actions.getZipCode(zip_code);
        if (resp) {
          form.setFieldsValue({
            [prefix && prefix === "sender_"
              ? `${prefix}zip`
              : "zip_code"]: zip_code,
            [prefix ? `${prefix}city` : "city"]: resp.countyName,
            [prefix ? `${prefix}district` : "district"]: resp.townName,
          });
        }
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
    },
    [app.actions, form, prefix]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let resp = await app.actions.getCounties();
        setCities(resp);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    })();
  }, [app.actions]);

  const getDistricts = useCallback(async () => {
    if (selectedCity) {
      setLoading(true);
      try {
        let resp = await app.actions.getDistricts(selectedCity);
        setDistricts(resp);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    }
  }, [app.actions, selectedCity]);

  useEffect(() => {
    getDistricts();
  }, [selectedCity, getDistricts]);

  useEffect(() => {
    checkedAddressIsValid();
  }, [checkedAddressIsValid, selectedCity]);

  return (
    <StyledWrapper layout="vertical" name="address">
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 16 }} />}
        style={{ alignSelf: "center" }}
      />
      <StyledFormItem
        name={prefix && prefix === "sender_" ? `${prefix}zip` : "zip_code"}
      >
        <Input
          placeholder="郵遞區號"
          disabled={loading || disabled}
          onBlur={() => {
            const currentZipCode = form.getFieldValue(
              prefix && prefix === "sender_" ? `${prefix}zip` : "zip_code"
            );
            if (/[0-9]{3}/g.test(currentZipCode)) {
              getZipCode(currentZipCode);
            }
          }}
          type="short"
          style={{ width: "100%" }}
        />
      </StyledFormItem>
      <StyledFormItem name={prefix ? `${prefix}city` : "city"}>
        <Select
          placeholder="縣市"
          onChange={value => {
            setSelectedCity(value);
            const zipKey =
              prefix && prefix === "sender_" ? "sender_zip" : "zip_code";

            form.setFieldsValue({
              [prefix ? `${prefix}city` : "city"]: value,
              [prefix ? `${prefix}district` : "district"]: null,
              [zipKey]: null,
            });
          }}
          disabled={loading}
        >
          {cities.map(c => (
            <SelectOption key={c.countyName} value={c.countyName}>
              {c.countyName}
            </SelectOption>
          ))}
        </Select>
      </StyledFormItem>
      <StyledFormItem name={prefix ? `${prefix}district` : "district"}>
        <Select
          placeholder="鄉鎮市區"
          onChange={value => {
            // set zip_code
            let instance = districts.find(t => t.townName === value);
            const zipKey =
              prefix && prefix === "sender_" ? "sender_zip" : "zip_code";
            if (instance) {
              form.setFieldsValue({
                [zipKey]: instance.zipCode,
              });
            }
            checkedAddressIsValid();
          }}
          disabled={loading}
        >
          {districts.map(t => (
            <SelectOption key={t.townName} value={t.townName}>
              {t.townName}
            </SelectOption>
          ))}
        </Select>
      </StyledFormItem>
      <StyledFormItem
        style={{ flex: "0 0 100%", maxWidth: "100%" }}
        name={prefix ? `${prefix}address` : "address"}
      >
        <Input
          placeholder="請填寫完整路段/號碼"
          disabled={loading}
          value={address}
          type="long"
        />
      </StyledFormItem>
    </StyledWrapper>
  );
}

const Select = styled(AntSelect).attrs({
  suffixIcon: <ArrowDown color={constants.colors.text} size={24} />,
  bordered: false,
  dropdownMatchSelectWidth: false,
})`
  display: flex;
  height: 48px;
  font-size: 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #b6bac3;
  background-color: ${constants.colors.background};

  .ant-select-arrow {
    top: 40%;
    width: 24px;
  }
`;

const SelectOption = styled(AntSelect.Option)`
  width: 150px;
`;

const Input = styled(AntInput)`
  padding: 8px 16px;
  border: none;
  background-color: ${constants.colors.background};
  margin: 6px 0px;
  flex-basis: 220px;
  flex-shrink: 0;
  ${props => (props.type === "short" ? "flex-basis: 90px; width: 90px;" : "")}
  ${props => (props.type === "long" ? "flex-basis: 320px;" : "")}

  .ant-input::selection {
    border-color: green;
  }
}
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledFormItem = styled(Form.Item)`
  flex: 0 0 100%;
  max-width: calc(33.33333% - 8px);
  box-sizing: border-box;

  .ant-select.ant-select-in-form-item {
    margin: 0;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    display: inline-flex;
    align-items: center;
  }
  .ant-row.ant-form-item {
    margin: 0;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px;
  }
`;
