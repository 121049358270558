import React, { useEffect, useState, useContext } from "react";
import { Form, Input, Checkbox, Button } from "antd";
import AddressField from "./AddressField";
import styled from "styled-components";
import formatValidator from "../../Utils/formatValidator";
import { Context } from "../../AppContext";
import useDimension from "../../hooks/use-dimension";

const constants = require("../../constants");

const { isNotEmpty, isMobileNumber, isValidZipCode } = formatValidator;

export default function ShippingInfo({
  userConfig,
  deliveryConfig,
  currentShippingType,
  setConfig,
  setShippingInfoExpand,
  editFinish,
}) {
  const app = useContext(Context);
  const [form] = Form.useForm();
  const [sameAsUser, setSameAsUser] = useState(false);
  const [valid, setValid] = useState(false);
  const { dimension } = useDimension();

  useEffect(() => {
    form.setFieldsValue({
      receiver_name: deliveryConfig.receiver_name,
      receiver_phone: deliveryConfig.receiver_phone,
      receiver_address: deliveryConfig.receiver_address,
      receiver_city: deliveryConfig.receiver_city,
      receiver_district: deliveryConfig.receiver_district,
      zip_code: deliveryConfig.zip,
      note: deliveryConfig.note,
      rstore_name: deliveryConfig.rstore_name,
      rstore_addr: deliveryConfig.rstore_addr,
    });
  }, [form, deliveryConfig]);

  useEffect(() => {
    if (sameAsUser) {
      form.setFieldsValue({
        ...deliveryConfig,
        receiver_name: userConfig.name,
        receiver_phone: userConfig.phone,
        receiver_address: userConfig.address,
        receiver_city: userConfig.city,
        receiver_district: userConfig.district,
        zip_code: userConfig.zip,
      });
      setValid(true);
    }
  }, [sameAsUser]);

  const checkValid = () => {
    const deliveryConfig = form.getFieldsValue();
    if (["xdelivery", "payuni"].includes(currentShippingType)) {
      setValid(
        isNotEmpty(deliveryConfig.rstore_name) &&
          isNotEmpty(deliveryConfig.rstore_addr) &&
          isNotEmpty(deliveryConfig.receiver_name) &&
          isMobileNumber(deliveryConfig.receiver_phone)
      );
    } else {
      setValid(
        isNotEmpty(deliveryConfig.receiver_name) &&
          isMobileNumber(deliveryConfig.receiver_phone) &&
          isNotEmpty(deliveryConfig.receiver_address) &&
          isNotEmpty(deliveryConfig.receiver_city) &&
          isNotEmpty(deliveryConfig.receiver_district) &&
          isNotEmpty(deliveryConfig.zip_code) &&
          isValidZipCode(deliveryConfig.zip_code)
      );
    }
  };

  const handleOnConfirm = () => {
    const deliveryConfig = form.getFieldsValue();
    setConfig("delivery", {
      ...deliveryConfig,
      zip: deliveryConfig.zip_code,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="shipping-info"
      onValuesChange={() => {
        checkValid();
      }}
      initialValues={{
        receiver_name: deliveryConfig.receiver_name,
        receiver_phone: deliveryConfig.receiver_phone,
        receiver_address: deliveryConfig.receiver_address,
        receiver_city: deliveryConfig.receiver_city,
        receiver_district: deliveryConfig.receiver_district,
        zip_code: deliveryConfig.zip,
        note: deliveryConfig.note,
        rstore_name: deliveryConfig.rstore_name,
        rstore_addr: deliveryConfig.rstore_addr,
      }}
    >
      <StyledFormItem name="same_as_user" valuePropName="checked">
        <Checkbox
          onChange={e => {
            setSameAsUser(e.target.checked);
          }}
        >
          同訂購人資料
        </Checkbox>
      </StyledFormItem>
      <StyledFormItem label="收件人姓名" name="receiver_name">
        <Input placeholder="請輸入證件本名" />
      </StyledFormItem>
      <StyledFormItem label="手機號碼" name="receiver_phone">
        <Input placeholder="請輸入手機號碼" />
      </StyledFormItem>
      {currentShippingType === "hct" && (
        <>
          <StyledFormItem label="通訊地址">
            <AddressField
              zip_code={deliveryConfig.receiver_zip}
              city={deliveryConfig.receiver_city}
              prefix="receiver_"
              form={form}
              setValid={setValid}
            />
          </StyledFormItem>
          <StyledFormItem label="收件備註" name="delivery_note">
            <Input placeholder="請輸入收件備註" />
          </StyledFormItem>
          <StyledFormItem name="info" valuePropName="checked">
            <Checkbox>儲存成為常用收件資訊</Checkbox>
          </StyledFormItem>
        </>
      )}
      {["xdelivery", "payuni"].includes(currentShippingType) && (
        <StyledFormItem label="取貨門市" name="rstore_name">
          {deliveryConfig.rstore_name !== "" && (
            <div
              style={{
                display: "flex",
                alignItems: "start",
                gap: "4px",
                marginBottom: "8px",
                fontSize: "16px",
              }}
            >
              <img
                src="/images/location_on.svg"
                alt="location"
                style={{
                  width: "24px",
                }}
              />
              {`${deliveryConfig.rstore_name}${deliveryConfig.rstore_addr}`}
            </div>
          )}
          <Button
            style={{
              border: `1px solid ${constants.colors.highlight}`,
              borderRadius: "10px",
              color: constants.colors.highlight,
              backgroundColor: "transparent",
              width: "96px",
              height: "44px",
            }}
            onClick={() => {
              const orginalWebsiteUrl = new URL(`${constants.webUrl}cart`);
              orginalWebsiteUrl.searchParams.append("step", "2");
              const url =
                currentShippingType === "payuni"
                  ? app.actions.getPayuniShipMap(
                      dimension.innerWidth < constants.breakpoints.lg,
                      encodeURIComponent(orginalWebsiteUrl)
                    )
                  : app.actions.getXdeliveryShipMap(
                      encodeURIComponent(orginalWebsiteUrl)
                    );
              window.open(url, "_self");
            }}
          >
            {deliveryConfig.rstore_name !== "" ? "更改門市" : "新增門市"}
          </Button>
        </StyledFormItem>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <StyledButton
          onClick={() => {
            setShippingInfoExpand(false);
          }}
          style={{
            marginTop: "16px",
            backgroundColor: "transparent",
            border: "none",
            color: constants.colors.text,
          }}
        >
          取消
        </StyledButton>
        <StyledButton
          // 驗證表單通過才能點擊
          disabled={!valid}
          onClick={() => {
            setShippingInfoExpand(false);
            editFinish();
            handleOnConfirm();
          }}
          style={{ marginTop: "16px" }}
        >
          確定
        </StyledButton>
      </div>
    </Form>
  );
}

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: #505050;
  }
  input {
    display: flex;
    margin: 0;
    height: 48px;
    padding: 12px 16px;
    align-items: center;
    align-self: stretch;
    color: ${constants.colors.text};
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #b6bac3;
  }
  .ant-radio-wrapper {
    font-size: 14px;
    color: #505050;
  }

  .ant-form-item {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${constants.colors.highlight};
    border-color: ${constants.colors.highlight};
  }
`;

const StyledButton = styled(Button)`
  height: 56px;
  font-size: 16px;
  border-radius: 12px;
  text-align: center;
  padding: 16px 24px;
  background-color: ${constants.buttons.background.normal.secondary};
  color: ${constants.buttons.text.normal.secondary};
  &:hover {
    background-color: ${constants.buttons.background.hover.secondary};
    color: ${constants.buttons.text.hover.secondary};
  }
`;
