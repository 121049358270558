import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Tooltip, Checkbox, Switch, Input } from "antd";

import { Context } from "../../AppContext";

import { getCalculation } from "../../Utils/OrderUtil";
import { calculateEstimatedShipmentDate } from "../../Utils/requireDayUtil";

import { Text, Button } from "../../Widgets";
import CouponBox from "./CouponBox";
import * as Icon from "../../Widgets/Icon";
import CheckoutAgreement from "../../Modals/CheckoutAgreement";
import OrderReminder from "../../Modals/OrderReminder";

const constants = require("../../constants");

function CartCalculation(props) {
  const app = useContext(Context);

  const {
    items_amount, //商品金額（未稅）
    items_tax, //商品金額（稅）
    discount, //活動折抵（未稅）
    discount_tax, //活動折抵（稅）
    fee, //運費(未稅)
    fee_tax, //運費（稅）
    bonus, //紅利（未稅）
    bonus_tax, //紅利（稅）
    amount, //訂單總計金額(含稅)
  } = getCalculation(props.calculations);
  const {
    cartDataItems,
    step,
    goToNextStep,
    handleOnSubmitOrder,
    InfoBoxFinished,
    termsAgreed,
    setTermsAgreed,
    config,
    setConfig,
    profileBonus,
    params,
    setParams,
  } = props;

  const [currentAgreementModal, setCurrentAgreementModal] = useState(null);
  const [reminderModal, setReminderModal] = useState(false);
  const [requireDate, setRequireDate] = useState("");
  const [showShippingDateInfo, setShowShippingDateInfo] = useState(true);

  const amountWithoutBonus = amount - bonus - bonus_tax;
  const totalFee = fee + fee_tax;
  const discountAmount = -(discount + discount_tax);
  const maxBonusUsage = Math.floor((items_amount + items_tax) * 0.3);

  // 判斷實際可以使用的 bonus 金額，如果 bonusTotal > profileBonus，則使用 profileBonus 作為 bonusTotal
  const bonusTotalAmount =
    maxBonusUsage > profileBonus ? profileBonus : maxBonusUsage;

  const showCheckoutBtn = () => {
    if (step === 0 || step === 1) {
      return true;
    }
    return false;
  };

  const showBonusSwitch = () => {
    /*
    訂單滿100元即可使用紅利, 最低折到15元 （發票問題）
    只能折訂單30％
    profile.bonus > 0
    */
    if (items_amount + items_tax > 100 && profileBonus > 0) {
      return true;
    }
  };

  const triggerReminderOnChecked = value => {
    if (value) {
      setReminderModal(true);
    }
  };

  const handleReminderOnCancel = () => {
    setTermsAgreed(false);
    setReminderModal(false);
  };

  useEffect(() => {
    const maxRequireDateStr = calculateEstimatedShipmentDate({
      cartItems: cartDataItems,
    });
    setRequireDate(maxRequireDateStr);
  }, [cartDataItems]);

  return (
    <CalculationsWrapper>
      <Calculations>
        <Text weight={700}>購物明細</Text>
        <AmountDetailWrapper>
          <Detail>
            <Text size="sm">商品金額</Text>
            <Text size="sm">{`NT$${items_amount + items_tax}`}</Text>
          </Detail>
          <Detail>
            <Text size="sm">活動折抵</Text>
            <Text size="sm" color={constants.colors.highlight}>
              {`-NT$${discountAmount}`}
            </Text>
          </Detail>
          <Detail>
            <Text size="sm">運費</Text>
            <Text size="sm">{totalFee === 0 ? "免運" : `NT$${totalFee}`}</Text>
          </Detail>
          {step === 1 && (
            <>
              <CouponBox
                params={params}
                setParams={setParams}
                config={config}
              />
              {showBonusSwitch() && (
                <BonusContainer>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                    }}
                  >
                    <Text size="sm">
                      {" "}
                      使用紅利金折抵{`${bonusTotalAmount}`}點{" "}
                    </Text>
                    <Switch
                      defaultChecked={config.extraConfig.use_bonus}
                      onChange={checked => {
                        setConfig({
                          ...config,
                          extraConfig: {
                            use_bonus: checked,
                          },
                        });
                      }}
                    />
                  </div>
                  {config.extraConfig.use_bonus && (
                    <>
                      <span>{`剩餘紅利：${profileBonus} 點 (最高可折抵訂單金額30%)`}</span>
                      <span
                        style={{
                          fontSize: 12,
                          color: constants.colors.highlight,
                        }}
                      >
                        紅利金若折抵後無論任何原因取消訂單，將無法補回紅利，請謹慎留意。
                      </span>
                    </>
                  )}
                </BonusContainer>
              )}
            </>
          )}
        </AmountDetailWrapper>
        <DetailWrapper>
          <Detail>
            <Tooltip
              title="此為預估出貨日期，實際出貨日期以審稿完成後為主"
              overlayInnerStyle={{
                backgroundColor: "#25272c",
                color: "#d8dBdf",
                border: "1px solid #40444c",
                borderRadius: "5px",
                padding: "8px 12px",
              }}
              placement="bottom"
            >
              <Date>
                <Text size="sm">預計出貨日期</Text>
                <Icon.InfoCircle
                  size={16}
                  color={"#1c1b1f"}
                  css={{ cursor: "pointer" }}
                />
              </Date>
            </Tooltip>
            <Text
              size="xl"
              color={"#FF5520"}
              weight={700}
              css={"line-height: 32px"}
            >
              {requireDate}
            </Text>
          </Detail>
          <Detail>
            <Text size="sm">總金額</Text>
            <Text
              size="xl"
              color={"#FF5520"}
              weight={700}
              css={"line-height: 32px"}
            >
              {step === 0 && `NT$${amountWithoutBonus}`}
              {step === 1 && `NT$${amount}`}
            </Text>{" "}
          </Detail>
        </DetailWrapper>
        <ShippingDateInfoWrapper>
          <div>
            <Text size="sm" color={constants.colors.highlight}>
              出貨日期說明
            </Text>
            {showShippingDateInfo ? (
              <Icon.MinusOutlined
                size={16}
                color={constants.colors.highlight}
                css={{ cursor: "pointer" }}
                onClick={() => setShowShippingDateInfo(false)}
              />
            ) : (
              <Icon.PlusOutlined
                size={16}
                color={constants.colors.highlight}
                css={{ cursor: "pointer" }}
                onClick={() => setShowShippingDateInfo(true)}
              />
            )}
          </div>
          {showShippingDateInfo && (
            <>
              <Text size="sm" color={`#575c66`} css={{ marginBottom: "8px" }}>
                預計出貨時間僅供參考，已扣除非工作日。實際出貨時間因生產流程、機器產能等影響，不在售後服務範圍內。
              </Text>
              <Text size="sm" color={`#575c66`}>
                同一訂單若包含不同工序商品，預計出貨時間會依照訂單中出貨時間最長的商品計算，且品項越多時間越久。
              </Text>
            </>
          )}
        </ShippingDateInfoWrapper>
        {showCheckoutBtn && step === 1 && InfoBoxFinished && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
              fontSize: 16,
            }}
          >
            <div style={{ display: "flex", gap: 8 }}>
              <Checkbox
                checked={termsAgreed}
                onChange={e => {
                  setTermsAgreed(e.target.checked);
                  triggerReminderOnChecked(e.target.checked);
                }}
              ></Checkbox>
              <div>
                我已閱讀並同意接受本網站之{" "}
                <PopUp onClick={() => setCurrentAgreementModal("terms")}>
                  條款聲明{" "}
                </PopUp>
                及{" "}
                <PopUp onClick={() => setCurrentAgreementModal("print_notice")}>
                  印刷須知
                </PopUp>
                詳列之所有內容{" "}
              </div>
            </div>
            <div style={{ display: "flex", gap: 4 }}>
              <Checkbox
                onChange={e => {
                  setParams({ is_share_order_photo: e.target.checked });
                }}
              ></Checkbox>
              我同意授權感官印刷分享此訂單成品照。
            </div>
            {params.is_share_order_photo && (
              <Input.TextArea
                style={{
                  height: "104px",
                  border: "solid 1px #EDEEF1",
                  borderRadius: "6px",
                  padding: "16px",
                  resize: "none",
                  boxShadow: "none",
                }}
                placeholder="歡迎留下您的社群帳號ID或推廣網址，讓感官文化於日後推播分享此訂單印件成品照～謝謝"
                onChange={e => setParams({ share_info: e.target.value })}
              />
            )}
          </div>
        )}
        {showCheckoutBtn &&
          (step === 0 ? (
            <Button size="lg" onClick={goToNextStep} children="去結帳" />
          ) : (
            <Button
              size="lg"
              onClick={handleOnSubmitOrder}
              disabled={!termsAgreed}
              children="下訂單"
            />
          ))}
      </Calculations>
      <CheckoutAgreement
        currentAgreementModal={currentAgreementModal}
        onConfirm={() => setCurrentAgreementModal(null)}
      />
      <OrderReminder
        visible={reminderModal}
        onConfirm={() => setReminderModal(false)}
        onCancel={() => handleReminderOnCancel()}
      />
    </CalculationsWrapper>
  );
}

const CalculationsWrapper = styled.div`
  min-width: 250px;
  margin-left: 16px;
  grid-column: 4 / 5;

  @media screen and (max-width: ${constants.breakpoints.xl}px) {
    margin-left: 0;
    margin-top: 24px;
  }
`;

const Calculations = styled.div`
  position: sticky;
  top: 104px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  background: #fff;

  @media screen and (max-width: ${constants.breakpoints.md}px) {
    border-radius: 0;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${constants.colors.highlight};
    border-color: ${constants.colors.highlight};
  }
  .ant-checkbox-wrapper {
    margin: 0;
  }
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  min-height: 80px;
`;
//繼承 DetailWrapper 的樣式
const AmountDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  border-bottom: solid 0.5px #edeef1;
  padding-bottom: 16px;
`;

const Detail = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-switch-checked {
    background-color: ${constants.colors.highlight};
  }
`;

const BonusContainer = styled(Detail)`
  flex-wrap: wrap;

  span {
    font-size: 14px;
    color: #5b616e;
  }
`;

const Date = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const PopUp = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${constants.colors.highlight};
  cursor: pointer;
`;

const ShippingDateInfoWrapper = styled.div`
  font-size: 16px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
`;

export default CartCalculation;
